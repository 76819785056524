<script>
  import { getApprovalClass } from './utils';
  import threeSelect from '@/component/muneAddressSelect/index.vue';
  import configUtils from '@/utils/config';

  export default {
    components: {
      threeSelect
    },
    props: {
      approvalTypeS: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        getApprovalClass,
        info: '123',
        Params: {
          approvalProcessList: [],
          cause: '', // 事由
          leaveType: '',
          startDate: '',
          department: '', // 部门
          endDate: '',
          roundTrip: '', // 单程往返（1:单程 2:往返）
          duration: '',
          approvalType: '',
          departmentId: '',
          remark: '',
          vehicle: '', // 交通工具（1:飞机 2:火车 3:汽车 4:其他）
          businessTravelList: []
        },
        options: [
          {
            value: '1',
            label: '事假'
          },
          {
            value: '2',
            label: '年假'
          },
          {
            value: '3',
            label: '病假'
          },
          {
            value: '4',
            label: '其他'
          }
        ],
        vehicleOptions: [
          {
            value: '1',
            label: '飞机'
          },
          {
            value: '2',
            label: '火车'
          },
          {
            value: '3',
            label: '汽车'
          },
          {
            value: '4',
            label: '其他'
          }
        ],
        departMember: [],
        detail_info: null, // 详情数据
        deptId: '', // 部门id
        DepMemberId: '',
        personList: [],
        isSelect: false,
        SelectOver: '',
        showIndex: '',
        sendParams: [],
        threeShow: false,
        productAddress: {
          disabled: false, // 只为了显示不能选择地址
          provinceId: '',
          cityId: '',
          districtId: ''
        },
        productAddress2: {
          disabled: true, // 只为了显示不能选择地址
          provinceId: '',
          cityId: '',
          districtId: ''
        },
        sendList: [
          {
            departureCity: '',
            departureDistrict: '',
            departureProvince: '',
            destinationCity: '',
            destinationDistrict: '',
            destinationProvince: ''
          }
        ],
        startAddress: {},
        valIndex: ''
      };
    },
    created() {
      // this.getPerson();
      this.Params.startDate = '';
      this.Params.endDate = '';
      this.getDepartmentInfo();
    },
    methods: {
      async getDepartmentInfo() {
        const getToken = window.localStorage.getItem(configUtils.baseToken);
        const api = this.$fetchApi.department.api + '?access_token=' + getToken;
        const res = await this.$fetchData.fetchGet({}, api, 'json');
        // console.log(res,'resss')
        try {
          const sendaArray = [];
          if (res.code === '200') {
            this.departMember = res.result;
            this.departMember.forEach((item) => {
              sendaArray.push({ value: item.id, label: item.name });
            });
            this.departMember = sendaArray;
          }
        } catch (e) {
          console.log(e, 'error');
        }
      },
      /** 返回三级地址信息 */
      placeInfo(val) {
        this.startAddress.departureProvince = val[0].name;
        this.startAddress.departureCity = val[1].name;
        this.productAddress2.disabled = false;
        this.startAddress.departureDistrict = val[2].name;
      },
      placeInfoOver(val) {
        // console.log(val)
        if (val.length === 3) {
          this.Params.businessTravelList[val[2]] = Object.assign({
            destinationProvince: val[0].name,
            destinationDistrict: val[2].name,
            destinationCity: val[1].name
          }, this.startAddress);
        }
        if (val.length === 4) {
          this.Params.businessTravelList[val[3]] = Object.assign({
            destinationProvince: val[0].name,
            destinationDistrict: val[2].name,
            destinationCity: val[1].name
          }, this.startAddress);
        }
        console.log(this.Params.businessTravelList)
      },
      onSubmit(e) {
        console.log(this.Params.businessTravelList)
        this.Params.approvalType = this.approvalTypeS;
        if (!(this.Params.startDate || this.Params.endDate)) {
          this.$message('时间不能为空');
          return false;
        } else if (!this.Params.cause) {
          this.$message('请假事由不可为空');
          return false;
        } else if (this.approvalTypeS === 1 && !this.Params.leaveType) {
          this.$message('请假类型不可为空');
          return false;
        } else if (!this.Params.duration) {
          this.$message('请假时长不可为空');
          return false;
        } else if (
          this.approvalTypeS === 2 &&
          !this.startAddress.departureProvince
        ) {
          console.log(this.startAddress.departureProvince)
          this.$message('出发地省份不可为空');
          return false;
        } else if (
          this.approvalTypeS === 2 &&
          this.Params.businessTravelList.length == 0
        ) {
          this.$message('目的地省份不可为空');
          return false;
        } else if (!this.Params.vehicle && this.approvalTypeS === 2) {
          this.$message('交通工具不可为空');
          return false;
        } else if (
          Array.isArray(this.Params.approvalProcessList) &&
          this.Params.approvalProcessList.length <= 0
        ) {
          console.log(this.Params, 'Params');
          this.$message('请选择审批人');
          return false;
        }
        this.Params.startDate = `${this.Params.startDate} 00:00:00`;
        this.Params.endDate = `${this.Params.endDate} 23:59:59`;
        if (this.approvalTypeS === 1) {
          delete this.Params.remark;
          delete this.Params.roundTrip;
          delete this.Params.vehicle;
          delete this.Params.businessTravelList;
        } else if (this.approvalTypeS === 3) {
          delete this.Params.leaveType;
          delete this.Params.remark;
          delete this.Params.roundTrip;
          delete this.Params.vehicle;
          delete this.Params.businessTravelList;
        } else if (this.approvalTypeS === 2) {
          console.log(this.Params);
        }

        this.approvalapply(this.Params);
      },
      async getDetailInfo() {
        const getToken = window.localStorage.getItem(configUtils.baseToken);
        const api = `${this.$fetchApi.getAttendanceDetail.api}${this.DepMemberId}?access_token=${getToken}`;
        const res = await this.$fetchData.fetchGet({}, api, 'json');

        if (res.code === '200') {
          this.detail_info = res.result;
        } else {
          console.log(111);
        }
      },
      /** 获取登录人信息 */
      async getPerson() {
        const api = this.$fetchApi.getPersonInfo.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        try {
          if (data.code === '200') {
            this.deptId = data.result ? data.result.deptId : '';

          }
        } catch (e) {
          console.log(e);
        }
      },
      async approvalapply(params) {
        const paramsINfo = JSON.parse(JSON.stringify(params));
        const SendArr = [];

        paramsINfo.approvalProcessList.forEach((item, index) => {
          if (item) {
            SendArr.push({ approvalLevel: item.approvalLevel, approverId: item.approverId, approver: item.approver });
          }
        });

        paramsINfo.approvalProcessList = SendArr;

        // console.log(SendArr, "SendArr");
        const api = this.$fetchApi.approvalapply.api;
        const data = await this.$fetchData.fetchPost(paramsINfo, api, 'json');
        try {
          if (data.code === '200') {
            this.$message(data.message);
            this.$emit('getType', false);
            this.$emit('Reloadlist', '11');
          } else {
            this.$message(data.message);
          }
        } catch (e) {
          console.log(e);
        }
      },
      selectPerson(index) {
        this.SelectOver = '';
        this.isSelect = true;
        this.showIndex = index;
      },
      showSelectInfo() {
        const refss = document.querySelector('el-popper is-multiple');
      },
      changeSelect(event) {
        console.log(event, 'event22');

        this.personList[this.showIndex] = this.detail_info[this.showIndex].filter(
          item => {
            return item.approverId === event;
          }
        );

        this.Params.approvalProcessList[this.showIndex] = this.personList[
          this.showIndex
          ][0];
        this.isSelect = !this.isSelect;

      },
      changeTime(Time) {
        // console.log(Time,'123')
        if (
          new Date(this.Params.endDate).getTime() <
          new Date(this.Params.startDate).getTime()
        ) {
          this.$message('开始时间不得大于结束时间');
          return false;
        }
        if (this.Params.startDate && this.Params.endDate) {
          this.Params.duration =
            (new Date(this.Params.endDate).getTime() -
              new Date(this.Params.startDate).getTime()) /
            86400000 +
            1;
        }
      },
      //    新增地址
      Addaddress() {
        this.sendList.push(
          {});
      },
      changeDepartMember(e) {
        console.log(e, 'eeeee');
        this.departMember.map((item) => {
          if (item.value === e) {
            this.Params.department = item.label;
            this.Params.departmentId = item.value;
          }
        });
        this.getDetailInfo();
      }
    },
    watch: {}
  };
</script>

<template>
  <div class="main">
    <el-form ref="Params" :model="Params" label-width="80px">
      <el-form-item label="请假类型" style="width: 17rem;" v-if="approvalTypeS === 1">
        <el-select v-model="Params.leaveType"
                   placeholder="请选择"
                   size="small"
                   popper-class="popperSelect"
        >
          <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="交通工具" style="width: 17rem;" v-if="approvalTypeS === 2">
        <el-select v-model="Params.vehicle" placeholder="请选择" size="small">
          <el-option
                  v-for="item in vehicleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单程/往返" style="width: 17rem;" v-if="approvalTypeS === 2">
        <el-select v-model="Params.roundTrip" placeholder="请选择" size="small">
          <el-option label="单程" value="1"></el-option>
          <el-option label="往返" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
              @click="threeShow = true"
              label="出发城市"
              style="width: 17rem;"
              v-if="approvalTypeS === 2"
      >
        <threeSelect @mAddressInfo="placeInfo" :address="productAddress" :isArea="true"/>
      </el-form-item>

      <br/>
      <el-form-item v-if="approvalTypeS === 2">
        <el-button type="primary" style="position: relative;top: 3.9rem;right: -6rem;" @click="Addaddress">新增
        </el-button>
      </el-form-item>
      <el-form-item
              @click="threeShow = true"
              label="目的地"
              style="width:17rem;"
              v-if="approvalTypeS === 2"
      >

        <threeSelect @mAddressInfo="placeInfoOver" :index="index" :isArea="true" :address="productAddress2"
                     v-for="(item,index) in sendList" :key="index"/>
      </el-form-item>

      <el-form-item label="开始时间" style="width: 5rem;">
        <el-date-picker
                size="small"
                v-model="Params.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                @change="changeTime"
                placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" style="width: 5rem;">
        <el-date-picker
                size="small"
                value-format="yyyy-MM-dd"
                v-model="Params.endDate"
                @change="changeTime"
                type="date"
                placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="时长" style="width: 16.5rem;">
        <el-input v-model="Params.duration" size="small" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="请假事由" style="width: 27rem;" v-if="approvalTypeS === 1">
        <el-input type="textarea" v-model="Params.cause" size="small"></el-input>
      </el-form-item>
      <el-form-item label="外出事由" style="width: 27rem;" v-if="approvalTypeS === 3">
        <el-input type="textarea" v-model="Params.cause" size="small"></el-input>
      </el-form-item>
      <el-form-item label="出差备注" style="width: 27rem;" v-if="approvalTypeS === 2">
        <el-input type="textarea" v-model="Params.remark" size="small"></el-input>
      </el-form-item>
      <el-form-item label="出差事由" style="width: 27rem;" v-if="approvalTypeS === 2">
        <el-input type="textarea" v-model="Params.cause" size="small"></el-input>
      </el-form-item>
      <el-form-item label="选择部门" style="width: 17rem;" >
        <el-select
                v-model="DepMemberId"
                placeholder="请选择"
                popper-class="popperSelect"
                @change="changeDepartMember"
        >
          <el-option
                  v-for="item in departMember"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"

          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批流程" style="line-height: 1rem;" v-if="DepMemberId">
        <div class="steps_box" v-if="detail_info">
          <div v-for="(item, index) in detail_info" :key="index">
            <div class="round">
              <i></i>
            </div>
            <div class="content">
              <div class="result">下一节点审批人</div>
              <div
                      :class="['name', getApprovalClass(item.approvalStatus).color]"
              >{{ getApprovalClass(item.approvalStatus).name }}
              </div>
            </div>
            <div class="person">
              <div>
                <img
                        src="../../../assets/images/myApplication/jia.png"
                        alt
                        @click="selectPerson(index)"
                        v-if="!(personList[index])"
                />
                <span class="head_portrait" v-else @click="selectPerson(index)">
                  <span
                          v-for="(itm, idx) in personList[index]"
                          style="color:#fff;"
                          :key="idx"
                  >{{itm.approver}}</span>
                </span>
                <div v-if="personList[index]">
                  <span
                          v-for="(itm, idx) in personList[index]"
                          style="font-size: 1rem;"
                          :key="idx"
                  >{{itm.approver}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-button type="primary" @click="onSubmit" style="margin: 0 auto;">提交</el-button>
    </el-form>
    <!--    -->
    <el-dialog width="30%" title="选择节点" :visible.sync="isSelect" append-to-body>
      <el-select
              v-if="isSelect"
              v-model="SelectOver"
              placeholder="请选择"
              @change="changeSelect"
              popper-class="popperSelect"
      >
        <el-option
                v-for="item in detail_info[showIndex]"
                :key="item.approverId"
                :label="item.approver"
                @click="showSelectInfo"
                :value="item.approverId"
        ></el-option>
      </el-select>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
  /*el-select-dropdown el-popper is-multiple*/
  /*/deep/ .el-select {*/
  /*  z-index: 999999 !important;*/
  /*}*/
  .steps_box {
    padding: 0 1.875rem 0 4.625rem;
    background-color: #fff;

    > div {
      position: relative;
      margin-top: 1rem;
      height: 6.5rem;

      &:first-child {
        margin-top: 0;
      }

      &::before {
        content: "";
        width: 0.213rem;
        height: 7.5rem;
        background-color: #e6e6e6;
        position: absolute;
        top: 3.5rem;
        left: -2rem;
        transform: translateX(-50%);
      }

      &:last-child {
        &::before {
          content: "";
          width: 0;
          height: 0;
        }
      }
    }

    .round {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #bfbfbf;
      top: 50%;
      left: -2.25rem;
      transform: translateY(-50%);
    }

    .content {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .result {
      font-size: 0.8rem;
      color: #333;
      line-height: 2rem;
    }

    .name {
      font-size: 0.75rem;
      color: #999;
      line-height: 0.15rem;
    }

    .person {
      position: absolute;
      top: 2rem;
      right: 0;
      text-align: center;

      > div {
        display: inline-block;
        font-size: 1rem;
        margin-left: 1rem;
      }

      img {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: #bfbfbf;
        margin: 0 auto;
      }

      .head_portrait {
        display: inline-block;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        text-align: center;
        line-height: 3rem;

        background-color: #1975ff;
        margin: 0 auto;
      }

      p {
        width: 4rem;
        height: 4rem;
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px dashed #bdcdf6;
        color: #bdcdf6;
        font-size: 1rem;
        text-align: center;
        line-height: 4rem;
        background-color: #fff;
        margin: 0.5rem auto;
      }

      span {
        font-size: 0.5rem;
        color: #999;
        line-height: 2.25rem;
      }
    }
  }
</style>
