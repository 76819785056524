<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="nav.name === '审批管理'" class="icon icon-approval"></i>
          <i v-if="nav.name === '我的申请'" class="icon icon-application"></i>
          <i v-if="nav.name === '统计报表'" class="icon icon-excel"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <div class="approval-manage">


    <header>
      <el-form :inline="true" :model="params" class="filter-form">
        <el-form-item label="申请类型">
          <el-select class="form-row" clearable size="small" v-model="params.approvalType" placeholder="请选择申请类型"
                     value="params.approvalType">
            <el-option label="请假" value="1"></el-option>
            <el-option label="出差" value="2"></el-option>
            <el-option label="外出" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时长(天)">
          <el-input class="form-row" size="small" v-model="params.duration" placeholder="请输入时长"></el-input>
        </el-form-item>
        <el-form-item label="提交时间">
          <div class="block">
            <el-date-picker
                    style="width:18rem"
                    v-model="Sendtime"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </el-form-item>
        <!--        <el-form-item label="申请人">-->
        <!--          <el-input class="form-row" v-model="params.applicant" placeholder="请输入申请人搜索"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item style="margin-left: .2rem;">
          <el-button type="primary" @click="onSubmit" size="small">查询</el-button>
        </el-form-item>
      </el-form>
    </header>
    <nav>
      <button @click="showDetail(1)">
        <div class="hide_icon">
          <img src="../../assets/images/myApplication/time.png" alt="">
        </div>
        <span>请假</span>
      </button>
      <button @click="showDetail(2)">
        <div class="hide_icon">
          <img src="../../assets/images/myApplication/feiji.png" alt="">
        </div>

        <span>出差</span>
      </button>
      <button @click="showDetail(3)">
        <div class="hide_icon">
          <img src="../../assets/images/myApplication/damen.png" alt="">
        </div>
        <span>外出</span>
      </button>
    </nav>
    <main>
      <el-table :data="tableData" stripe style="width: 100%">
        <!--        <el-table-column align="center" prop="realName" label="申请人"></el-table-column>-->
        <el-table-column align="center" prop="approvalType" label="申请类型">
          <template slot-scope="scope">
            <div>{{ ['','请假','出差','外出'][scope.row.approvalType] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="duration" label="时长"></el-table-column>
        <el-table-column align="center" prop="startDate" label="开始时间"></el-table-column>
        <el-table-column align="center" prop="endDate" label="结束时间"></el-table-column>
        <el-table-column align="center" prop="createdDate" label="提交申请时间"></el-table-column>
        <el-table-column align="center" prop="name" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.approvalStatus === 1" style="color: #FD8F36">审批中</span>
            <span v-if="scope.row.approvalStatus === 2" style="color: #41B873">已通过</span>
            <span v-if="scope.row.approvalStatus === 3" style="color: #666666">已驳回</span>
            <span v-if="scope.row.approvalStatus === 4" style="color: #666666">已撤回</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="detailsRow(scope.row, 'detail')" type="text" size="small">详情</el-button>
            <el-button
                    v-if="scope.row.approvalStatus === 1 || scope.row.approvalStatus === 2"
                    @click="approvalRow(scope.row)" type="text" size="small">撤回
            </el-button>
            <el-button v-else :disabled="true" type="text" size="small">撤回</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :total="total"
                :page-size="params.pageSize"
                :current-page.sync="params.pageNum"
                @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </main>

  </div></div>
        </div>
    <!--    弹出层-->

    <el-dialog :title="['','请假','出差','外出'][approvalTypeS]"

               :visible.sync="isShow"
               style="text-align: center;"
               width="75%"
               top="1%"
    >
      <leavePop
              v-if="isShow"
              :approvalTypeS="approvalTypeS"
              @getType="getType"
              @Reloadlist="ReloadlistInfo"
      />
    </el-dialog>
    <el-dialog title="撤回申请" :visible.sync="cancelVisible" width="400px" align="center">
      <div class="cancel-dialog">
        <el-input type="textarea" :rows="4" placeholder="在此输入撤回原因" v-model="refuseReason"></el-input>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="cancelSave">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="detailTitle" :visible.sync="detailVisible" width="65%" top="3%" align="center" v-if="detailData">
      <detailData :detailData="detailData"  @approvalClose="detailVisible = false" />
    </el-dialog>
  </div>
</template>
<script>


  import leavePop from './components/leave';
  import detailData from './components/dutyDetail';

import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";


  export default {
    components: {
      leavePop,
      detailData,
      nfBreadcrumb
    },
    data() {
      return {
        detailData: '',
        detailApproval: '',
        detailVisible: false,
        detailTitle: '',
        isShow: false,
        total: 10,
        Sendtime: [],
        params: { // 表格发送数据
          startDate: '', // 开始时间
          endDate: '', // 结束时间
          duration: '', // 时长
          applicant: '', // 申请人
          approvalType: '', // 申请类型 （1:请假 2:出差 3:外出）
          approvalStatus: '', // 审批状态 （1:审批中 2:已通过 3:已驳回 4:已撤回）
          pageNum: 1, // 页码
          pageSize: 10 // 页大小
        },
        tableData: [ // 表格数据来源
          {
            id: '1',
            name: '123',
            state: 1,
            status: 1
          }
        ],
        approvalTypeS: Number,
        cancelVisible: false,
        refuseReason: '',
        id: '',

      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "勤务管理"
        }
      ],
      activeNav: 1,
      tabsNav: [
        {
          name: "审批管理",
          url: "approvalManagement"
        },
        {
          name: "我的申请",
          url: "myApplication"
        }
        // ,
        // {
        //   name: "统计报表",
        //   url: "dutyStatistics"
        // }
      ],
      };
    },
    created() {
      this.getInfoList(this.params);
    },
    methods: {
      async cancelSave() {
        if (!this.refuseReason) {
          return this.$message('请填写撤回原因！');
        }
        const params = {
          approvalId: this.id,
          approvalStatus: 4,
          refuseReason: this.refuseReason
        };
        const data = await this.$fetchData.fetchPost(params, '/api/attendance/v1/approval/revocation', 'json');
        if (data.code === '200') {
          this.$message({ type: 'success', message: '已成功撤回' });
          this.cancelVisible = false;
          //关闭弹出框
          this.getInfoList(this.params);
          //刷新表格
        } else {
          this.$message({ type: 'error', message: data.message });
        }
      },
      // 改编页大小
      handleSizeChange(pageSize) {
        this.params.pageSize = pageSize;
        this.getInfoList(this.params);
      },
      // 改变页码
      handleCurrentChange(pageNum) {
        this.params.pageNum = pageNum;
        this.getInfoList(this.params);
      },
      // 提交数据
      onSubmit() {
        this.getInfoList(this.params);
      },
      ReloadlistInfo() {
        this.getInfoList(this.params);
      },
      // 详情
      async detailsRow(data, type) {
        this.detailApproval = type;
        if (type === 'detail') {
          this.detailTitle = data.approvalType === 1 ? '请假详情' : (data.approvalType === 2 ? '出差详情' : '外出详情');
        } else {
          this.detailTitle = data.approvalType === 1 ? '请假审批' : (data.approvalType === 2 ? '出差审批' : '外出审批');
          this.detailList.opinion = '';
          this.detailList.textarea = '';
        }
        const res = await this.$fetchData.fetchGet({}, '/api/attendance/v1/approval/' + data.id);
        if (res.code === '200') {
          this.detailVisible = true;
          this.detailData = res.result;
        } else {
          this.$message({ type: 'error', message: res.message });
        }
      },
      approvalRow(info) {
        // 撤回按钮
        this.cancelVisible = true;
        this.id = info.id;
        console.log(info);
      },
      async getInfoList(params) {
        // 列表数据
        const api = this.$fetchApi.MyApplylist.api;
        try {
          const res = await this.$fetchData.fetchGet(params, api, 'json');
          this.tableData = res.result.rows;
          this.total = res.result.total;
          console.log(res.result);
        } catch (e) {
          console.log(e, 123);
        }
      },
      showDetail(type) {
        this.isShow = true;
        this.approvalTypeS = type;
        console.log(this.approvalTypeS, '11111111111111111111');
      },
      getType(info) {
        console.log(info, 'info');
        this.isShow = info;
        this.getInfoList(this.params);
      }
    },
    watch: {
      Sendtime(val) {
        // this.params.duration = ((new Date(val[1]).getTime() - new Date(val[0]).getTime()) / 86400000) + 1;
        this.params.startDate = `${val[0]} 00:00:00`;
        this.params.endDate = `${val[1]} 23:59:59`;
      }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    // padding: 1.23rem;
  }
  .approval-manage{
  padding: 20px 10px;}

  nav {
    /*margin-top: 1rem;*/
    margin-bottom: 1rem;
    display: flex;

    button {
      width: 5.71rem;
      height: 2.29rem;
      background: rgba(51, 128, 254, 1);
      border-radius: .25rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .6rem 1rem;
      font-size: .9rem;
      /*font-family:Microsoft YaHei;*/
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      /*line-height:1.71rem;*/
      margin-right: 1rem;

      .hide_icon {
        width: .9rem;
        height: .9rem;
        overflow: hidden;
        margin-right: .5rem;
      }

      img {
        width: 1rem;
        height: 1rem;
      }
    }

  }


  /deep/ .el-icon-date {
    display: none;
  }

  /*/deep/ .el-dialog {*/
  /*  margin: 0;*/
  /*}*/
  .table-pagination {
    text-align: center;
    margin: 40px 0;
  }

  .nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-approval {
      background-image: url("../../assets/images/dutyManagement/icon_approval.png");
    }

    &-application {
      background-image: url("../../assets/images/dutyManagement/icon_application.png");
    }

    &-excel{
      background-image: url("../../assets/images/dutyManagement/icon_excel.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
