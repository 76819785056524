export function getApprovalClass(type) {
  const params = {
    name: '',  // 对应的文字名
    class: '', // 对应的标签类
    color: ''  // 对应的字体颜色类
  };

  switch (type) {
    case 2:
      params.name = '已通过';
      params.class = 'audit_success';
      params.color = 'success_color';
      break;
    case 3:
      params.name = '未通过';
      params.class = 'audit_refuse';
      params.color = 'danger_color';
      break;
    case 4:
      params.name = '已撤回';
      params.class = 'audit_withdraw';
      params.color = 'withdraw_color';
      break;
    default:
      params.name = '待审批';
      params.class = 'audit_await';
      params.color = 'warning_color';
      break;
  }

  return params;
}
